import { useTranslate } from '@tolgee/react';
import useAudioCapture from '../hooks/useAudioCapture';
import { Box, Button, HStack, Select, Text, VStack, useToast } from '@chakra-ui/react';
import useWebSocket from '../hooks/useWebSocket';
import { useContext, useState } from 'react';
import { StoreContext } from '../store';
import { IMediaRecorder } from 'extendable-media-recorder';

interface IEvent {
	inCall: boolean;
}

export default function AudioRecorder() {
	const { t } = useTranslate();
	const toast = useToast();
	const [webSocket, setSocket] = useState<WebSocket>();
	const { currentApp } = useContext(StoreContext);
	const { connect } = useWebSocket(currentApp);
	const { capturing, inputs, setInput, startCapture, input } = useAudioCapture();
	const {
		state: { status },
		setStatus
	} = useContext(StoreContext);
	const [inCall, setInCall] = useState(false);
	const [port, setPort] = useState<MessagePort>();
	const [recorder, setRecorder] = useState<IMediaRecorder>();
	const [closing, setClosing] = useState(false);
	const [sampleRates, setSampleRates] = useState<{ desktop: number; microphone: number } | null>(null);

	const connectSocket = async (port: MessagePort, recorder: IMediaRecorder, rates: { desktop: number; microphone: number }) => {
		if (!port) return console.log('No port?');
		if (!recorder) return console.log('No recorder?');
		setInCall(true);
		var socket: WebSocket | undefined = connect(rates); // Pass the rates
		setSocket(socket);
		port.postMessage({ inCall: true });

		if (socket) {
			socket.onclose = () => {
				setInCall(false);
				port.postMessage({ inCall: false });
				socket = undefined;
				console.log('Stopped recording?');
				// stopCapture();
				setClosing(false);
			};

			recorder.ondataavailable = (event) => {
				new Response(event.data).arrayBuffer().then((buffer) => {
					try {
						if (socket && socket.OPEN) socket.send(buffer);
					} catch (e) {
						console.error(e);
					}
				});
			};
		}
	};

	const startRecording = async () => {
		if (!input) {
			toast({
				title: t('Error'),
				description: t('Please select a microphone before starting the call.'),
				status: 'error',
				duration: 5000,
				isClosable: true
			});
			return;
		}

		const audioContext = new AudioContext();
		console.log('start recording...');
		const { recorder, desktop, sampleRates: capturedRates  } = await startCapture();
		if (recorder && desktop && capturedRates) {
			console.log('captured rates:', capturedRates);
			setSampleRates(capturedRates); // Store in state
			if (status.page.content.right_upper_panel.message_list.length === 0) {
				status.page.content.right_upper_panel.message_list = [
					{
						content: '',
						role: 'assistant',
						rateable: true
					}
				];
				setStatus(status);
			}

			await audioContext.audioWorklet.addModule('worklet/call-detector.js');
			const deskSource = audioContext.createMediaStreamSource(desktop);

			const node = new AudioWorkletNode(audioContext, 'call-detector');
			deskSource.connect(node);

			setPort(node.port);
			setRecorder(recorder);

			node.port.onmessage = (event: MessageEvent<IEvent>) => {
				if (event.data.inCall) {
					connectSocket(node.port, recorder, capturedRates); // Pass sample rates here
				}
			};
		}
	};

	const stopRecording = async () => {
		setClosing(true);
		if (webSocket && webSocket.OPEN)
			webSocket.send(JSON.stringify({ type: 'end_call', payload: status }));
	};

	return (
		<VStack>
			<Select
				placeholder={t('apps.transcript.audio_recorder.microphone')}
				onChange={(e) => setInput(e.target.value)}
			>
				{inputs.map((input) => (
					<option key={input.deviceId} value={input.deviceId}>
						{input.label}
					</option>
				))}
			</Select>
			<HStack w="100%">
				{!capturing && !inCall && (
					<Button
						w="100%"
						isDisabled={capturing || inCall || closing}
						onClick={startRecording}
					>
						{t('apps.transcript.audio_recorder.start')}
					</Button>
				)}
				{capturing && !inCall && (
					<Button
						w="100%"
						isDisabled={inCall || closing}
						onClick={() => port && recorder && sampleRates && connectSocket(port, recorder, sampleRates)}
					>
						{t('apps.transcript.audio_recorder.force')}
					</Button>
				)}
				{capturing && inCall && (
					<Button
						w="100%"
						isLoading={closing}
						isDisabled={!capturing}
						onClick={stopRecording}
					>
						<HStack justifyItems="center" alignItems="center">
							{inCall && <Box w={2} h={2} bg="red" borderRadius={32}></Box>}
							<Text>{t('apps.transcript.audio_recorder.stop')}</Text>
						</HStack>
					</Button>
				)}
			</HStack>
		</VStack>
	);
}
