import { useEffect, useState } from 'react';
import { IMediaRecorder, MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

// AudioWorklet processor code as inline string
const workletProcessor = `
class SampleRateDetector extends AudioWorkletProcessor {
  process() {
    this.port.postMessage({ sampleRate });
    return false; // Stop after first detection
  }
}
registerProcessor('sample-rate-detector', SampleRateDetector);
`;

const detectActualSampleRate = async (stream: MediaStream): Promise<number> => {
	const audioContext = new AudioContext();
	try {
		// Add worklet processor from inline code
		const blob = new Blob([workletProcessor], { type: 'application/javascript' });
		const url = URL.createObjectURL(blob);
		await audioContext.audioWorklet.addModule(url);

		const source = audioContext.createMediaStreamSource(stream);
		const detector = new AudioWorkletNode(audioContext, 'sample-rate-detector');

		return new Promise((resolve) => {
			detector.port.onmessage = (event) => {
				resolve(event.data.sampleRate);
				audioContext.close();
			};
			source.connect(detector);
			detector.connect(audioContext.destination);
		});
	} catch (error) {
		console.warn('AudioWorklet failed, falling back to base sampleRate:', audioContext.sampleRate);
		audioContext.close();
		return audioContext.sampleRate;
	}
};

export default function useAudioCapture() {
	const [capturing, setCapturing] = useState(false);
	const [desktopStream, setDesktopStream] = useState<MediaStream>();
	const [microphoneStream, setMicrophoneStream] = useState<MediaStream>();
	const [recorder, setRecorder] = useState<IMediaRecorder>();
	const [chunks, setChunks] = useState<Blob[]>([]);
	const [input, setInput] = useState('');
	const [inputs, setInputs] = useState<MediaDeviceInfo[]>([]);
	const [port, setPort] = useState<MessagePort>();
	const [isRegistered, setIsRegistered] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				// Initialize media device permissions
				await navigator.mediaDevices.getUserMedia({ audio: true });
				const devices = await navigator.mediaDevices.enumerateDevices();
				setInputs(devices.filter(d => d.kind === 'audioinput'));

				// Register WAV encoder once
				if (!isRegistered) {
					const newPort = await connect();
					await register(newPort);
					setPort(newPort);
					setIsRegistered(true);
				}
			} catch (err) {
				console.error(`Initialization Error: ${err}`);
			}
		})();
	}, [isRegistered]);

	const getHardwareSampleRates = async (deskStream: MediaStream, micStream: MediaStream) => {
		const [desktopActual, micActual] = await Promise.all([
			detectActualSampleRate(deskStream),
			detectActualSampleRate(micStream)
		]);

		return {
			desktop: desktopActual,
			microphone: micActual
		};
	};

	const startCapture = async (
		options?: DisplayMediaStreamOptions
	): Promise<{
		recorder?: IMediaRecorder;
		combined?: MediaStream;
		desktop?: MediaStream;
		microphone?: MediaStream;
		sampleRates?: { desktop: number; microphone: number };
	}> => {
		if (!port) {
			console.error('Encoder port not initialized');
			return {};
		}

		try {
			// Acquire media streams
			const [deskStream, micStream] = await Promise.all([
				desktopStream || navigator.mediaDevices.getDisplayMedia({
					audio: {
						echoCancellation: false,
						noiseSuppression: false,
						autoGainControl: false,
						sampleRate: { ideal: 48000 }
					}
				}),
				microphoneStream || navigator.mediaDevices.getUserMedia({
					audio: {
						deviceId: { exact: input },
						sampleRate: { ideal: 48000 }
					}
				})
			]);

			// Configure audio processing
			const sampleRates = await getHardwareSampleRates(deskStream, micStream);
			const audioContext = new AudioContext();
			const output = audioContext.createMediaStreamDestination();

			// Create audio graph
			const sources = [
				audioContext.createMediaStreamSource(deskStream),
				audioContext.createMediaStreamSource(micStream)
			];

			const merger = audioContext.createChannelMerger(2);
			sources.forEach((source, index) => source.connect(merger, 0, index));
			merger.connect(output);

			// Initialize recorder
			const recorder = new MediaRecorder(output.stream, {
				mimeType: 'audio/wav'
			});

			recorder.ondataavailable = (event) => {
				event.data.size > 0 && setChunks(prev => [...prev, event.data]);
			};

			recorder.start(100);

			// Update state
			setRecorder(recorder);
			setDesktopStream(deskStream);
			setMicrophoneStream(micStream);
			setCapturing(true);

			return {
				recorder,
				combined: output.stream,
				desktop: deskStream,
				microphone: micStream,
				sampleRates
			};
		} catch (err) {
			console.error(`Capture Error: ${err}`);
			return {};
		}
	};

	const stopCapture = () => new Promise<string>((resolve, reject) => {
		if (!recorder) return reject('No active recording session');

		recorder.onstop = () => {
			const blob = new Blob(chunks, { type: 'audio/wav' });
			const url = URL.createObjectURL(blob);

			setCapturing(false);
			setRecorder(undefined);
			setChunks([]);

			resolve(url);
		};

		try {
			recorder.stop();
		} catch (err) {
			reject(`Stop capture failed: ${err}`);
		}
	});

	return {
		capturing,
		startCapture,
		stopCapture,
		setInput,
		inputs,
		input
	};
}
